@import url('https://fonts.googleapis.com/css2?family=Secular+One&display=swap');

.Navbar {
  height: 64px;
  background-color: #c67736;
  display: flex;
  justify-content: space-between;
}

.wordart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 64px;
  margin-left: 16px;
}

.wordart img {
  height: 40px;
}

.wordart .name {
  margin: 0 10px;
  color: #ffffff;
  font-family: 'Secular One';
  font-size: 40px;
  text-shadow: 1px 1px #000000;
}