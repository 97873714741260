.Footer {
  height: 128px;
  background-color: #c67736;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.Footer > div {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.version {
  align-items: flex-start;
}

.copyright {
  align-items: center;
}

.contact {
  align-items: flex-end;
}

.Footer a {
  color: black;
  text-decoration: none;
}

.Footer a:hover {
  text-decoration: underline;
}